import { useTheme } from "@material-ui/core"
import React, { SVGProps } from "react"

type Props = SVGProps<SVGSVGElement> & {
  light?: boolean
}

export const GMDMain = ({ light, ...props }: Props) => {
  const { palette } = useTheme()
  return (
    <svg
      width={594.001}
      height={591.859}
      fill={light ? palette.primary.contrastText : "black"}
      viewBox="0 0 594.001 591.859"
      {...props}
    >
      <g data-name="Group 121">
        <g data-name="Group 120">
          <g data-name="Group 23" fillRule="evenodd">
            <path
              data-name="Path 1"
              d="M8412.012 12819.53l-203.822.007c0 34.813 9.449 59.258 27.859 75.449 30.9 27.174 75.56 39.969 117.361 22.248 23.487-9.955 43.529-26.477 52.447-50.9 5.29-14.481 6.494-30.254 6.155-46.804z"
              transform="translate(16752 5000.509) translate(-17415 -5245) translate(-7350.273 -12319.671)"
            />
            <path
              data-name="Path 2"
              d="M3589.856 6393.439c13.619-2.684 30 18.243 41.47 29.709L3775.36 6566.6c6.021 6.02 11.482 12.3 17.44 18.261l71.516 71.288c4.592 4.58 13.8 11.42 15.494 20.14 2.3 11.84-7.358 18.474-12.261 23.3L3669.9 6896.522c-12.12 12.132-23.734 23.417-35.875 35.527-5.969 5.954-11.415 12.239-17.438 18.262-4.2 4.2-12.508 13.6-20.455 15.254-10.934 2.275-19.479-7.565-23.748-11.849l-250.885-250.8c-18.436-17.914-23.176-24.208-3.322-43.5 6.236-6.061 11.7-11.665 17.865-17.83l232.992-233c4.584-4.586 11.766-13.361 20.822-15.147zm-1.119-9.494c-14.2 2.409-28.811 20.69-40.791 32.678l-223.133 222.187c-22.944 22.943-44.3 37.228-14.094 67.367l55.547 55.313c24.967 24.968 49.428 49.076 74.4 74.044l111.568 111.091c6.332 6.337 12.188 11.656 18.118 18.509 4.586 5.3 15.7 11.809 26.867 10.012 12.821-2.063 29.943-22.21 40.524-32.8L3767.5 6812.8a2869.922 2869.922 0 0018.857-18.727l74.527-73.913c6.147-6 12.672-11.986 18.379-18.262 5.515-6.064 12.273-13.853 10.143-26.652-2.254-13.544-21.7-29.726-32.646-40.667-12.552-12.553-24.485-24.685-37.194-37.026-12.729-12.36-24.644-24.46-37.2-37.018-12.943-12.944-24.477-24.354-37.172-37.048l-111.565-111.1c-6.073-6.073-12.653-11.99-18.284-18.35-5.438-6.141-15.613-11.964-26.6-10.1z"
              transform="translate(16752 5000.509) translate(-17415 -5245) translate(-2632.806 -6139.1)"
            />
            <path
              data-name="Path 3"
              d="M4217.442 7039.211c6.582-4.229 10.876 3.006 19.131 11.261 5.712 5.712 10.772 10.686 16.5 16.383l195.769 196c38.405 38.444 40.811 31.335 20.312 51.834l-228.767 227.827c-16.337 16.337-17.443 22.04-27.048 12.217-2.855-2.919-5.1-5.1-7.988-7.989l-212.739-211.913c-5.171-5.176-30.129-28.719-32.218-32.7-3.152-6.007 1.286-8.537 4.033-11.145 2.931-2.784 5.123-5.118 7.99-7.984 5.709-5.709 10.711-10.752 16.44-16.443 7.078-7.03 27.114-26.3 32.2-32.62 7.793-9.683 146.78-146.481 163.151-162.854 5.754-5.751 26.859-27.778 33.234-31.874zm-.226-10.727c-8.785 2.375-28.883 24.826-36.073 32.016l-220.318 219.365c-22.818 22.711-8.364 28.579 21.145 58.093l169.461 168.757c3.026 3.025 5.517 5.379 8.469 8.442l41.907 42.647c23.294 23.347 28.715 8.392 59.066-21.115l169.019-169.189c11.508-11.5 22.374-22.265 33.876-33.767 5.852-5.853 11-11.142 16.922-16.9 4.218-4.1 13.869-11.722 10.708-22.319-2.639-8.848-25.308-29.115-31.914-35.722l-220.311-219.372c-4.36-4.375-11.546-13.752-21.957-10.936z"
              transform="translate(16752 5000.509) translate(-17415 -5245) translate(-3260.662 -6757.907)"
            />
            <path
              data-name="Path 4"
              d="M8231.16 12134.67c-1.595-1.492-.937.114-1.47-2.57a8.693 8.693 0 01.264-2.46l8.062-.484c3.178.464 1.493.429 2.336.821l4.188 1.375c.854-3.9 1.035-4.383-.56-7.275-3.3-.406-2.568.865-6.912 1.208l-26.526-1.093c-1.776 2.846-1.574 3.178-.644 6.794 3.565.044 3.367-1.459 15.786-1.459l.232 5.215-17.757.069v13.244h204.354V12135l-19.426-.29.04-5.191c12.734.023 8.7 1.359 13.927 1.488 2.694-9.876-.446-5.69-15.92-5.72-4.427-.009-9.816.438-13.628-.525-5.4-1.364-6.54-1.218-5.125 6.261 4.314-.044 2.458-1.5 15.711-1.5l.315 5.211h-75.253l.115-5.22c9.038.016 12.205 1.674 14.431 1l.343-5.637c-4.623-1.367-20.222 1.983-31.78-.595-4.89-1.091-4.558 1.865-3.354 6.395 1.664.279 10.649-1.333 16.069-1.037l.223 4.939z"
              transform="translate(16752 5000.509) translate(-17415 -5245) translate(-7350.244 -11651.735)"
            />
            <path
              data-name="Path 5"
              d="M8810.708 15554.063l.056 75.778c-7.816 2.638-7.362 15.313 2.525 15.235 4.594-.035 6.963-2 7.4-6.39.548-5.468-1.089-7.7-4.633-8.872v-76c4.08 2.756 53.132 64.5 60.319 70.616a13.435 13.435 0 007.983 16.62c11.614 3.653 13.666-15.707-.7-19.067-5.849-1.367-7.8-5.515-14.133-13.122l-43.945-50.911c-7.731-8.5-3.4-8.371-18.919-8.264l-18.785 21.436a50.142 50.142 0 00-4.769 5.58l-29.085 32.928c-1.747 2.154-3.265 3.189-5 5.3-.892 1.087-1.149 1.722-2 2.733-4.635 5.521-5.375-.236-10.8 5.16-12 11.944 3.961 22.856 11.052 11.021 7.429-12.4-11.7 3.486 24.178-35.948z"
              transform="translate(16752 5000.509) translate(-17415 -5245) translate(-7853.199 -14941.479)"
            />
            <path
              data-name="Path 6"
              d="M9648.819 9758.986c-13.267 8.359-72.927 51.492-78.979 54.3 1.389 3.586 1.67 4.034 3.992 6.1l62.99-44.123c2.039-1.43 14.343-10.428 15.48-10.677.563-.124 12.954 5.28 17.558-4.262 4.157-8.618-8.9-23.1-18.1-12.375-3.413 3.99-1.993 5.993-2.941 11.037z"
              transform="translate(16752 5000.509) translate(-17415 -5245) translate(-8657.896 -9366.317)"
            />
            <path
              data-name="Path 7"
              d="M9520.049 9752.068a16.724 16.724 0 006.592-4.437c-4.524-3.014-9.4-6.533-14.312-9.951-3.675-2.558-11.186-8.105-14.958-9.555 1.219-19.156-17.766-18.579-21.062-4.748-1.339 5.617 1.692 8.432 5.063 10.487 6.328 3.861 8-.065 11.733.041 1.918.055 25.082 16.494 26.944 18.162z"
              transform="translate(16752 5000.509) translate(-17415 -5245) translate(-8567.764 -9336.723)"
            />
            <path
              data-name="Path 8"
              d="M10886.18 10786.309l33.557 22.82c3.58 2.485 8.077 6.124 12.155 7.927l3.7-4.991-43.162-30.375c-2.946 1.128-4.495 2.829-6.25 4.619z"
              transform="translate(16752 5000.509) translate(-17415 -5245) translate(-9922.005 -10362.689)"
            />
            <path
              data-name="Path 9"
              d="M8751.329 12259.515l-.547-4.7h9.739c-.843-.392.842-.356-2.336-.82l-8.062.484a8.651 8.651 0 00-.264 2.459c.532 2.686-.126 1.079 1.47 2.571z"
              transform="translate(16752 5000.509) translate(-17415 -5245) translate(-7870.414 -11776.58)"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}
