import React from "react"
import { useTranslation } from "react-i18next"
import { makeStyles } from "@material-ui/core"

import { Layout } from "components/layouts/index-layout"

import { useStaticQuery, graphql } from "gatsby"

const useStyles = makeStyles(theme => ({
  dateText: {
    fontSize: theme.typography.h5.fontSize,
    fontFamily: "Philosopher",
    textAlign: "center",
  },
  cover: {
    width: "100%",
    // maxHeight: "86vh",
  },
}))

const IndexPage = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const images = useStaticQuery(graphql`
    query {
      guitar: file(relativePath: { eq: "gmd-2024-bg-mobile-min.png" }) {
        childImageSharp {
          original {
            src
          }
        }
      }
      guitarHorizontal: file(relativePath: { eq: "gmd-2024-cover-min.png" }) {
        childImageSharp {
          original {
            src
          }
        }
      }
    }
  `)

  return (
    <Layout pageName={t("page.main")}>
      <div
        style={{
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <picture>
          <source
            srcSet={images.guitar.childImageSharp.original.src}
            media="(max-width: 900px)"
          />
          <img
            className={classes.cover}
            src={images.guitarHorizontal.childImageSharp.original.src}
            alt="crowd"
          />
        </picture>
      </div>
    </Layout>
  )
}

export default IndexPage
