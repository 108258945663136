import { TFunction } from 'i18next'

import ENDPOINTS from 'constants/endpoints'

export const getMenu = (t: TFunction) => [
  {name: t('page.about'), link: ENDPOINTS.ABOUT},
  {name: t('page.map'), link: ENDPOINTS.MAP},
  {name: t('page.participate'), link: ENDPOINTS.PARTICIPATE.HOME},
  {name: t('page.rules'), link: ENDPOINTS.FAQ},
  {name: t('page.sponsors'), link: ENDPOINTS.SPONSORS},
  {name: t('page.contacts'), link: ENDPOINTS.CONTACTS},
]
