import React, { Component } from "react"
import { Grid, Hidden } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

import { NavigationBar, MobileTopBar, MobileBottomBar } from "../navigation"
import SEO from "components/seo"
import { MobileDrawer, useMenuState } from "components/mobile-drawer"

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: "100vh",
    overflowY: "hidden",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
  },
  main: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    flexGrow: 3,
    [theme.breakpoints.down("md")]: {
      justifyContent: "flex-start",
    },
    [theme.breakpoints.up("md")]: {
      justifyContent: "center",
    },
  },
  footer: {
    padding: "1.0875rem 0px",
    textAlign: "center",
    color: "#88508F",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
    [theme.breakpoints.up("md")]: {
      display: "block",
    },
  },
  fullWidth: {
    width: "100%",
  },
  container: {
    width: "inherit",
    margin: "unset",
  },
}))

interface Props {
  children?: Component | JSX.Element | Array<JSX.Element>
  pageName: string
}

export const Layout = ({ children, pageName }: Props) => {
  const classes = useStyles({})
  const { isMenuOpen, closeMenu, toggleMenu } = useMenuState()

  return (
    <div className={classes.root}>
      <SEO title={pageName} />
      <Hidden smDown implementation="css">
        <Grid item xs className={classes.fullWidth}>
          <NavigationBar lightText />
        </Grid>
      </Hidden>
      <Hidden mdUp implementation="css">
        <MobileTopBar lightText />
        <MobileDrawer isMenuOpen={isMenuOpen} onClose={closeMenu} />
        <MobileBottomBar onToggleMenu={toggleMenu} fixed transparent light />
      </Hidden>
      <main className={classes.main}>{children}</main>
      <footer className={classes.footer}>
        © GMD {new Date().getFullYear()}
      </footer>
    </div>
  )
}
