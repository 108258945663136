import React, { useState } from 'react'
import { makeStyles, Drawer, List, ListItem, ListItemText, IconButton, Theme } from '@material-ui/core'
import { Clear } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
import { navigate } from 'gatsby';

import { getMenu } from 'utilities/menu'

const useDrawerStyles = makeStyles((theme: Theme) => ({
  menuList: {
    background: theme.palette.primary.main,
  },
  menuItem: {
    textAlign: 'center',
  },
  close: {
    justifyContent: 'center',
  },
}));

export const MobileDrawer = (props: {
  onClose: () => void;
  isMenuOpen: boolean;
  light?: boolean;
}) => {
  const classes = useDrawerStyles(props);
  const { t } = useTranslation();

  return (
    <Drawer
      open={props.isMenuOpen}
      onClose={props.onClose}
      anchor='bottom'
    >
      <List
        className={classes.menuList}
      >
        {getMenu(t).map(({ name, link }) => {
          return (
            <ListItem button key={name} onClick={() => {
              props.onClose();
              navigate(link);
            }}>
              <ListItemText className={classes.menuItem} primary={name} primaryTypographyProps={{
                variant: 'h4'
              }} />
            </ListItem>
          )
        })}
        <ListItem className={classes.close}>
          <IconButton color='inherit' onClick={props.onClose}>
            <Clear fontSize='large' />
          </IconButton>
        </ListItem>
      </List>
    </Drawer>
  )
}

export const useMenuState = (): {
  isMenuOpen: boolean;
  openMenu: () => void;
  closeMenu: () => void;
  toggleMenu: () => void;
} => {
  const [isMenuOpen, setMenuState] = useState(false);
  return {
    isMenuOpen,
    closeMenu: () => {
      setMenuState(false);
    },
    openMenu: () => {
      setMenuState(true);
    },
    toggleMenu: () => {
      setMenuState(!isMenuOpen)
    }
  }
}
