import React, { ReactNode } from "react"
import { Link, navigate } from "gatsby"
import { useTranslation } from "react-i18next"
import { makeStyles } from "@material-ui/core/styles"
import {
  AppBar,
  Grid,
  Toolbar,
  Button,
  Box,
  Typography,
  Theme,
  Hidden,
  IconButton,
} from "@material-ui/core"
import { Menu, MusicNote, Map } from "@material-ui/icons"
import clsx from "clsx"

import { LanguageSelect } from "./language-select"
import { UserContext } from "./global-context"
import { getMenu } from "utilities/menu"
import ENDPOINTS from "constants/endpoints"
import { GMDMain } from "components/logos/gmd-main-transparent"
import { LogoutButton } from "./logout-button"
import { isBrowser } from "utilities/gatsby"
import { light } from "@material-ui/core/styles/createPalette"

const useStyles = makeStyles((theme: Theme) => ({
  heading: {
    flexWrap: "nowrap",
  },
  navigationContainer: {
    flexShrink: 0,
  },
  title: {
    textDecoration: "none",
    textTransform: "uppercase",
    fontWeight: "bold",
    whiteSpace: "nowrap",
    color: ({ lightText }) =>
      lightText
        ? theme.palette.primary.contrastText
        : theme.palette.common.black,
  },
  link: {
    textTransform: "uppercase",
    color: ({ lightText }) =>
      lightText ? theme.palette.primary.contrastText : "initial",
  },
  bold: {
    fontWeight: "bold",
  },
  mobileBar: {
    backgroundColor: "transparent",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    left: 0,
    width: "100%",
  },
}))

interface Props {
  noHeading?: boolean
  transparent?: boolean
  lightText?: boolean
}
export const NavigationBar = ({
  noHeading = false,
  transparent = false,
  lightText = false,
}: Props) => {
  const location = isBrowser() && window.location.pathname
  const classes = useStyles({
    lightText,
  })
  const { t } = useTranslation()

  const isCurrentLocation = (name: string) => {
    const regex = RegExp(name)
    return regex.test(location as string)
  }

  const context = React.useContext(UserContext)
  return (
    <nav className={classes.navigationContainer}>
      <AppBar
        color={transparent ? "transparent" : "primary"}
        position="static"
        elevation={0}
      >
        <Toolbar>
          <Grid
            container
            justify="space-between"
            alignItems="center"
            wrap="nowrap"
          >
            <Grid item>
              {!noHeading && (
                <Box pl={1}>
                  <Grid
                    container
                    spacing={2}
                    alignItems="center"
                    className={classes.heading}
                  >
                    <Grid item>
                      <Link to="/">
                        <GMDMain width={50} height={50} light={lightText} />
                      </Link>
                    </Grid>
                    <Grid item>
                      <Hidden mdDown implementation="css">
                        <Typography
                          className={classes.title}
                          component={Link}
                          to={ENDPOINTS.HOME}
                          variant="h5"
                        >
                          {t("title.gmd")}
                        </Typography>
                      </Hidden>
                    </Grid>
                  </Grid>
                </Box>
              )}
            </Grid>
            <Grid item>
              {getMenu(t).map(({ name, link }) => (
                <Button
                  size="large"
                  key={name}
                  component={Link}
                  to={link}
                  className={clsx(classes.link, {
                    [classes.bold]: isCurrentLocation(link),
                  })}
                >
                  {name}
                </Button>
              ))}
              <Box pl={2} component="span">
                <LanguageSelect lightText={lightText} />
                {context && context.user && context.user.token && (
                  <LogoutButton
                    light={lightText}
                    onLogout={() => {
                      context && context.resetUser()
                    }}
                  />
                )}
              </Box>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </nav>
  )
}

interface BarProps {
  lightText?: boolean
  children?: ReactNode
}

export const MobileTopBar = (props: BarProps) => {
  const context = React.useContext(UserContext)
  const classes = useStyles(props)
  return (
    <AppBar className={classes.mobileBar} position="sticky" elevation={0}>
      <Toolbar>
        <Grid
          container
          justify="space-between"
          alignItems="center"
          wrap="nowrap"
        >
          <Grid item>
            <Link to="/">
              <GMDMain width={50} height={50} light={props.lightText} />
            </Link>
          </Grid>

          {props.children && <Grid item>{props.children}</Grid>}

          <Box component="span" whiteSpace="nowrap">
            <LanguageSelect lightText={props.lightText} />
            {context && context.user && context.user.token && (
              <LogoutButton
                light={props.lightText}
                onLogout={() => {
                  context && context.resetUser()
                }}
              />
            )}
          </Box>
        </Grid>
      </Toolbar>
    </AppBar>
  )
}

const useBottomBarStyles = makeStyles((theme: Theme) => ({
  bottomBar: {
    padding: theme.spacing(1),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100vw",
    background: ({ transparent }) =>
      transparent ? "transparent" : theme.palette.primary.main,
    color: ({ light }) =>
      light ? theme.palette.primary.contrastText : "initial",
    boxShadow: theme.shadows[3],
    zIndex: theme.zIndex.appBar,
  },
  menuText: {
    color: ({ light }) =>
      light ? theme.palette.primary.contrastText : "initial",
  },
  bottomBarButton: {
    width: "100%",
    fontWeight: "bold",
    lineHeight: 1,
  },
  fixedBottomBar: {
    position: "fixed",
    bottom: 0,
    zIndex: theme.zIndex.appBar,
  },
}))

export const MobileBottomBar = (props: {
  onToggleMenu: () => void
  fixed?: boolean
  transparent?: boolean
  light?: boolean
}) => {
  const classes = useBottomBarStyles(props)
  const { t } = useTranslation()
  return (
    <Grid
      container
      justify="center"
      className={clsx({
        [classes.bottomBar]: true,
        [classes.fixedBottomBar]: props.fixed,
      })}
    >
      <Grid item xs={4}>
        <Button
          className={classes.bottomBarButton}
          onClick={() => {
            navigate(ENDPOINTS.PARTICIPATE.HOME)
          }}
        >
          <div>
            <div>
              <MusicNote className={classes.menuText} />
            </div>
            <div className={classes.menuText}>{t("page.participate")}</div>
          </div>
        </Button>
      </Grid>

      <Grid item container justify="center" xs={4}>
        <IconButton color="inherit" onClick={props.onToggleMenu}>
          <Menu className={classes.menuText} />
        </IconButton>
      </Grid>
      <Grid item xs={4}>
        <Button
          className={classes.bottomBarButton}
          onClick={() => {
            navigate(ENDPOINTS.MAP)
          }}
        >
          <div>
            <div>
              <Map className={classes.menuText} />
            </div>
            <div className={classes.menuText}>{t("page.map")}</div>
          </div>
        </Button>
      </Grid>
    </Grid>
  )
}
